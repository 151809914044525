// Container
// ==========================================================================

@layer base {
  .container {
    @apply mx-auto w-full px-4 max-w-container;
  }

  .container-fluid {
    @apply mx-auto w-full px-4;
  }

  .container-small {
    @apply max-w-container-small px-4 mx-auto;
  }
}

.conatiner .container,
.container-fluid .container,
.container-fluid .container-xl,
.container .container-small,
.container .container-xl,
.container-xl .container {
  padding-left: 0;
  padding-right: 0;
}
