.aimeos-container.aimeos {
  @apply fixed inset-0 flex justify-center items-center z-50 p-16;
  @apply lg:top-header-height-desktop;
  top: 0 !important;

  .basket-standard__bg {
    @apply hidden;
  }

  .basket-standard,
  .account-watch,
  .catalog-list {
    @apply bg-white h-full p-8 overflow-y-auto container;

    &__inner {
      @apply p-0 shadow-none relative;
    }
  }

  .aimeos-container__closer {
    @apply block absolute right-0 top-2;
  }

  .catalog-list {
    @apply p-0;
  }

  & > .minibutton {
    @apply hidden;
  }

  // Watch popup
  .account-watch {
    &__title {
      @apply text-center border-b border-white-rock pb-4;
    }

    .watch-items {
      @apply lg:grid-cols-3;
    }
  }
}

.aimeos-overlay {
  @apply fixed inset-0 bg-black z-50;
  opacity: .5 !important;
}