// Accordian

.accordian-item {
  border-radius: 2rem;
  @apply mb-4 last:mb-0 block transition-all;
}

.accordian-item__title {
  @apply relative rounded-full font-medium bg-white select-none appearance-none shadow-accordian-item py-4 px-7 pr-16 md:pr-16 md:py-5 md:px-14;
}

.accordian-item__opener-icon {
  @apply absolute top-1/2 -translate-y-1/2 right-9 fill-current transition-all;
}

.accordian-item__content {
  @apply hidden text-base px-6 py-5 md:px-12 md:py-10 overflow-hidden max-w-full font-normal;
}

.accordian-item[open] {
  @apply bg-white shadow-accordian-item;
}

.accordian-item[open] .accordian-item__title {
  @apply text-primary;
}

.accordian-item[open] .accordian-item__opener-icon {
  @apply scale-flip-y;
}

.accordian-item[open] .accordian-item__content {
  @apply block;
}
