// Slick Slider

.slick-list {
  @apply relative;
  @apply w-full;
  @apply overflow-hidden;

  .js-blog-images & {
    @apply h-full;
  }
}

.catalog-detail-image .image-single,
.catalog-detail-image .thumbs {
  @apply opacity-0;
  @apply transition-opacity;
}

.catalog-detail-image .image-single.slick-initialized,
.catalog-detail-image .thumbs {
  @apply opacity-100;
}

.slick-track {
  @apply flex;
  @apply w-full;
  @apply max-w-full;
  @apply whitespace-nowrap;

  .js-blog-images & {
    @apply h-full;
  }
}

.catalog-detail-image .slick-track .media-item {
  @apply min-w-full;
  @apply whitespace-normal;
}

.catalog-detail-image .thumbs,
.js-blog-thumbs {
  @apply flex;
  @apply relative;
  @apply items-center;
  @apply pb-1;
}

.catalog-detail-image .thumbs .slick-track {
  @apply flex-1;
}

.catalog-detail-image .thumbs .item-thumb {
  @apply block;
  @apply max-w-1/4;
  @apply mx-2;
  @apply shadow-md;
  @apply object-cover;
}

.catalog-detail-image .thumbs .slick-arrow,
.js-blog-thumbs .slick-arrow,
.js-news-slider .slick-arrow {
  @apply text-transparent;
  @apply transition-all;
  @apply bg-center;
  font-size: 0;
  line-height: 0;
  width: 57px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 17'%3E%3Cpath fill='%2339605E' fill-rule='nonzero' d='m15.671.963.104.08 8.925 7.58-8.944 7.27a1.2 1.2 0 0 1-1.611-1.774l.097-.087 4.985-4.055H1.786a1.2 1.2 0 0 1-.123-2.393l.123-.006h17.976l-5.54-4.706a1.2 1.2 0 0 1-.217-1.587l.079-.105a1.2 1.2 0 0 1 1.587-.217Z'/%3E%3C/svg%3E");
  background-size: 25px 16px;
  background-repeat: no-repeat;

  /* Hover */
  @apply hover:opacity-60;
  @apply focus:opacity-60;

  &.slick-disabled {
    @apply opacity-60 pointer-events-none;
  }
}

.catalog-detail-image .thumbs .slick-arrow.slick-prev,
.js-blog-thumbs .slick-arrow.slick-prev,
.js-news-slider .slick-arrow.slick-prev {
  transform: scaleX(-1);
}

// Blog thumbnails
.js-blog-thumbs {
  @apply flex justify-center pt-7 px-5;

  li {
    @apply relative;

    img {
      @apply w-full h-full object-cover;
    }
  }

  &.slick-initialized {
    @apply mb-4;
  }
}

.js-blog-thumbs .slick-list,
.js-blog-thumbs .slick-track {
  // @apply flex w-full;
}

.js-blog-thumbs .slick-track {
  padding: 0 !important;
  @apply flex justify-center;
}

.js-blog-thumbs .slick-track {
}

// News post sliders
.js-news-slider.slick-initialized {
  @apply flex-wrap overflow-visible justify-center;

  .slick-list {
    @apply order-1 pb-4;
  }

  .slick-track {
    @apply -mx-4;
  }

  .slick-prev {
    @apply order-2;
  }

  .slick-dots {
    @apply flex order-3 items-start h-4 text-none;

    li {
      @apply px-0.5;
      @apply only:hidden;

      button {
        @apply w-4 h-4 rounded-full bg-akaroa text-none transition-all duration-200 ease-in-out;
        @apply hover:bg-primary;
      }

      &.slick-active button {
        @apply bg-primary;
      }
    }
  }

  .slick-next {
    @apply order-4;
  }
}

.js-news-slider .slick-list,
.js-news-slider .slick-track {
  @apply flex overflow-visible;

  .news-block__item {
    @apply opacity-50 transition-opacity;

    .news-block__article {
      @apply pointer-events-none;
    }

    &.slick-active {
      @apply opacity-100;

      .news-block__article {
        @apply pointer-events-auto;
      }
    }
  }
}

