@layer base {
  /* Custom Fonts */
  /* frank-ruhl-libre-regular - latin */
  @font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.woff2')
        format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* frank-ruhl-libre-500 - latin */
  @font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 500;
    src: local(''),
      url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.woff2')
        format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* frank-ruhl-libre-700 - latin */
  @font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    src: local(''),
      url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.woff2')
        format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* poppins-200 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local(''),
      url('../fonts/poppins/poppins-v15-latin-200.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/poppins/poppins-v15-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* poppins-300 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local(''),
      url('../fonts/poppins/poppins-v15-latin-300.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/poppins/poppins-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''),
      url('../fonts/poppins/poppins-v15-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/poppins/poppins-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* poppins-500 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local(''),
      url('../fonts/poppins/poppins-v15-latin-500.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/poppins/poppins-v15-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* poppins-600 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local(''),
      url('../fonts/poppins/poppins-v15-latin-600.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/poppins/poppins-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local(''),
      url('../fonts/poppins/poppins-v15-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/poppins/poppins-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  body {
    @apply font-sans;
    @apply font-light;
    @apply text-sm;
    @apply bg-spring-wood;
    @apply max-w-full;
    @apply text-black;
    @apply mt-header-height;
    @apply overflow-x-hidden;
    @apply md:text-base;
    @apply lg:mt-header-height-desktop;

    &.js-nav-active,
    &.js-basket-active,
    &.js-searchbar-active {
      @apply overflow-hidden;
    }
  }

  img {
    @apply max-w-full;
    @apply h-auto;
  }

  h1,
  h2,
  h3 {
    @apply font-serif;
    @apply font-bold;
  }

  strong {
    @apply font-medium;
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  .container {
    @apply mx-auto;
    @apply w-full;
    @apply px-4;
    @apply max-w-container;
  }

  .container-fluid {
    @apply mx-auto;
    @apply w-full;
    @apply px-4;
  }

  .container-small {
    @apply max-w-container-small;
    @apply px-4;
    @apply mx-auto;
  }

  /* Hidden */
  .catalog-stage-navigator {
    @apply hidden;
  }

  .container-xl,
  .navbar,
  .breadcrumb {
    @apply container;
  }

  .table-responsive {
    @apply w-full max-w-full;
  }

  hr {
    @apply border-t border-akaroa;
  }
}
