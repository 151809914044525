.navbar {
  &-wrapper {
    @apply fixed inset-0 bg-spring-wood top-header-height invisible opacity-0 transition-all;
    @apply w-full h-full flex justify-center md:justify-start items-center md:items-start lg:top-header-height-desktop;
    @apply xl:bg-transparent xl:transform-none xl:static xl:w-auto xl:h-auto xl:inline-flex xl:flex-1 xl:visible xl:opacity-100 xl:justify-center;

    .js-nav-active & {
      @apply opacity-100 visible z-10;
    }
  }

  &__list {
    @apply flex flex-col w-full h-full mt-2;
    @apply xl:flex-row xl:w-auto xl:h-auto xl:mt-0;
  }

  &__item {
    @apply font-medium mb-2;
    @apply xl:px-5 xl:mb-0;
    @apply md:first:pl-0;
    @apply md:last:pr-0;

    &--auth {
      @apply xl:border-l xl:border-black;
    }

    &-link {
      @apply font-medium;
      @apply hover:text-primary;
    }


    &.active {
      @apply text-primary;
    }
  }

  &__opener {
    @apply relative inline-flex flex-col -mr-4; 
    @apply hover:opacity-100 focus:opacity-100;

    &--dash {
      @apply relative w-5 h-0.2 bg-black mb-1 transition-transform duration-300 ease-in-out;
      @apply last:mb-0;

      &:nth-child(2) {
        @apply transition-none;
      }

      .js-nav-active & {
        &:first-child {
          @apply transform rotate-45;
          top: 0.375rem;
        }

        &:nth-child(2) {
          @apply opacity-0;
        }

        &:last-child {
          @apply transform -rotate-45;
          top: -0.375rem;
        }
      }
    }
  }
}