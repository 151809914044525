.product-filter {
  @apply py-7;

  &__heading {
    @apply text-center pb-8;

    &-title {
      @apply text-2xl md:text-46px mb-2 md:mb-4;
    }
  }

  &__filter {
    @apply text-center pb-3 mb-12 border-b border-white-rock;

    h1,
    h2,
    .attr-Varutyp legend {
      @apply font-serif font-bold text-19px opacity-50 mb-3;
    }

    .attr-list {
      @apply flex flex-wrap justify-center pb-8;

      .attr-item {
        input[type='checkbox'] {
          @apply sr-only;

          &:checked {
            & + label {
              @apply bg-white-rock;
            }
          }
        }

        label {
          @apply flex items-center justify-center px-5 py-2 mb-3 mx-1.5 bg-white rounded-full border border-white-rock p-4 font-medium cursor-pointer transition;
          @apply hover:bg-white-rock;
          @apply focus:bg-white-rock;
        }

        .icon {
          @apply mr-3;
        }

        .flag {
          @apply hidden;
        }

        .media-list {
          @apply hidden;
        }

        .attr-count {
          @apply sr-only;
        }

        &.disabled {
          input:checked + label {
            @apply opacity-100;
          }
        }
      }
    }

    .attr-Land legend,
    .attr-download legend,
    .catalog-list-footer {
      @apply hidden;
    }

    .attribute-lists .attr-Varutyp,
    .attribute-lists .attr-Varutyp .attr-list {
      display: flex !important; // To avaoid the display: none added inline
    }

    // For Filter Options
    &--options {
      .attr-list .attr-item .media-list {
        @apply inline-flex;

        .media-item {
          @apply mr-3 hidden first:inline-flex;
        }

        img {
          @apply block max-w-22px w-full h-auto;
        }
      }
    }
  }

  .catalog-list-items {
    .stock-list {
      .articleitem {
        @apply hidden;
      }

      .articleitem:only-child {
        &.stock-actual {
          @apply block;
        }
      }
    }
  }

  .pagination,
  a.btn.supplier-selected,
  .catalog-list-head,
  .catalog-list-footer,
  div.attribute-selected {
    @apply hidden;
  }
}
