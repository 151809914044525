.checkout-standard-address {
  @apply block;

  .form-horizontal {
    @apply border-t border-white-rock py-3.5 md:pt-8;

    &__item {
      @apply py-3.5 max-w-800px mx-auto border-b border-white-rock last:border-none;
      @apply md:px-0 md:py-8;
    }
  }

  .checkout-standard-address {
    @apply py-4 flex justify-center;
  }

  .block-heading__title {
    @apply text-center mb-2;
  }

  .item-address {
    @apply flex flex-col;

    .header {
      @apply flex w-full md:text-center md:justify-center pb-2 md:items-center last:pb-3;

      input {
        @apply inline-flex w-18px h-18px border border-primary mr-3 rounded-none text-primary relative;

        &.checkbox--new-option {
          @apply sr-only;

          & + label {
            @apply font-medium text-primary underline;
            @apply hover:no-underline;
          }
        }
      }

      label {
        @apply inline-flex;
      }

    }
    .form-item.error {
      input,
      input:focus {
        border-color: #dc3545 !important;
        box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
      }
    }
  }
}
