@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  /* Custom Fonts */
  /* frank-ruhl-libre-regular - latin */
  @font-face {
    font-family: "Frank Ruhl Libre";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.woff2") format("woff2"), url("../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-regular.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* frank-ruhl-libre-500 - latin */
  @font-face {
    font-family: "Frank Ruhl Libre";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.woff2") format("woff2"), url("../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-500.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* frank-ruhl-libre-700 - latin */
  @font-face {
    font-family: "Frank Ruhl Libre";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.woff2") format("woff2"), url("../fonts/frank-ruhl-libre/frank-ruhl-libre-v6-latin-700.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-200 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    src: local(""), url("../fonts/poppins/poppins-v15-latin-200.woff2") format("woff2"), url("../fonts/poppins/poppins-v15-latin-200.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-300 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    src: local(""), url("../fonts/poppins/poppins-v15-latin-300.woff2") format("woff2"), url("../fonts/poppins/poppins-v15-latin-300.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-regular - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../fonts/poppins/poppins-v15-latin-regular.woff2") format("woff2"), url("../fonts/poppins/poppins-v15-latin-regular.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-500 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("../fonts/poppins/poppins-v15-latin-500.woff2") format("woff2"), url("../fonts/poppins/poppins-v15-latin-500.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-600 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: local(""), url("../fonts/poppins/poppins-v15-latin-600.woff2") format("woff2"), url("../fonts/poppins/poppins-v15-latin-600.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("../fonts/poppins/poppins-v15-latin-700.woff2") format("woff2"), url("../fonts/poppins/poppins-v15-latin-700.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  body {
    @apply font-sans;
    @apply font-light;
    @apply text-sm;
    @apply bg-spring-wood;
    @apply max-w-full;
    @apply text-black;
    @apply mt-header-height;
    @apply overflow-x-hidden;
    @apply md:text-base;
    @apply lg:mt-header-height-desktop;
  }
  body.js-nav-active, body.js-basket-active, body.js-searchbar-active {
    @apply overflow-hidden;
  }

  img {
    @apply max-w-full;
    @apply h-auto;
  }

  h1,
h2,
h3 {
    @apply font-serif;
    @apply font-bold;
  }

  strong {
    @apply font-medium;
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  .container {
    @apply mx-auto;
    @apply w-full;
    @apply px-4;
    @apply max-w-container;
  }

  .container-fluid {
    @apply mx-auto;
    @apply w-full;
    @apply px-4;
  }

  .container-small {
    @apply max-w-container-small;
    @apply px-4;
    @apply mx-auto;
  }

  /* Hidden */
  .catalog-stage-navigator {
    @apply hidden;
  }

  .container-xl,
.navbar,
.breadcrumb {
    @apply container;
  }

  .table-responsive {
    @apply w-full max-w-full;
  }

  hr {
    @apply border-t border-akaroa;
  }
}
@layer base {
  .container {
    @apply mx-auto w-full px-4 max-w-container;
  }

  .container-fluid {
    @apply mx-auto w-full px-4;
  }

  .container-small {
    @apply max-w-container-small px-4 mx-auto;
  }
}
.conatiner .container,
.container-fluid .container,
.container-fluid .container-xl,
.container .container-small,
.container .container-xl,
.container-xl .container {
  padding-left: 0;
  padding-right: 0;
}

.searchbar {
  @apply fixed inset-0 top-header-height lg:top-header-height-desktop opacity-0 invisible transition-all;
}
.js-searchbar-active .searchbar {
  @apply visible opacity-100;
}
.searchbar h1 {
  @apply hidden;
}
.searchbar__wrapper {
  @apply max-w-container mx-auto xl:px-4 relative z-20;
}
.searchbar__wrapper .catalog-filter {
  @apply w-full;
  @apply md:w-auto;
}
.searchbar__overlay {
  @apply absolute inset-0 bg-black bg-opacity-50 z-10;
}

.search-form {
  @apply w-full px-5;
  @apply md:px-0;
}
.search-form__input {
  @apply max-w-full mx-auto relative;
  @apply md:max-w-full;
}
.search-form__input input {
  @apply rounded-full bg-spring-wood border-primary border-opacity-70 font-medium text-base py-2 pl-7 pr-24 z-10 w-full md:w-searcbar;
}
.search-form button {
  @apply hidden;
}
.search-form__submit[type=submit] {
  @apply inline absolute right-0 top-0 bottom-0 h-full z-20 transition-opacity;
  @apply hover:opacity-90 focus:opacity-90;
}

.ui-autocomplete {
  @apply z-50 max-w-searchbar-suggestion md:w-searcbar max-h-96 overflow-y-auto shadow-md translate-y-3 overflow-x-hidden rounded-b-md;
  @apply md:max-w-full;
}
.ui-autocomplete .ui-menu-item {
  @apply block border-b border-spring-wood;
  @apply first:mt-3;
}
.ui-autocomplete .ui-menu-item a.suggest-item {
  @apply block p-3 md:px-4 bg-white transition-all;
  @apply hover:underline hover:bg-spring-wood;
  @apply focus:underline focus:bg-spring-wood;
}

/* Button */
.btn {
  @apply inline-flex items-center rounded-full text-base font-medium bg-primary text-white py-2 px-7 transition;
  @apply hover:bg-opacity-90;
}
.btn--contact {
  @apply bg-roman transition-all p-3.5 md:px-7;
  @apply hover:opacity-90;
  @apply focus:opacity-90;
}
.btn--big {
  @apply md:py-4 md:px-9 md:text-sm;
}
.btn:disabled {
  @apply opacity-60 pointer-events-none;
}
.btn .icon--left {
  @apply mr-3 -ml-2;
}

.searchbar {
  @apply fixed inset-0 top-header-height lg:top-header-height-desktop opacity-0 invisible transition-all;
}
.js-searchbar-active .searchbar {
  @apply visible opacity-100;
}
.searchbar h1 {
  @apply hidden;
}
.searchbar__wrapper {
  @apply max-w-container mx-auto xl:px-4 relative z-20;
}
.searchbar__wrapper .catalog-filter {
  @apply w-full;
  @apply md:w-auto;
}
.searchbar__overlay {
  @apply absolute inset-0 bg-black bg-opacity-50 z-10;
}

.search-form {
  @apply w-full px-5;
  @apply md:px-0;
}
.search-form__input {
  @apply max-w-full mx-auto relative;
  @apply md:max-w-full;
}
.search-form__input input {
  @apply rounded-full bg-spring-wood border-primary border-opacity-70 font-medium text-base py-2 pl-7 pr-24 z-10 w-full md:w-searcbar;
}
.search-form button {
  @apply hidden;
}
.search-form__submit[type=submit] {
  @apply inline absolute right-0 top-0 bottom-0 h-full z-20 transition-opacity;
  @apply hover:opacity-90 focus:opacity-90;
}

.ui-autocomplete {
  @apply z-50 max-w-searchbar-suggestion md:w-searcbar max-h-96 overflow-y-auto shadow-md translate-y-3 overflow-x-hidden rounded-b-md;
  @apply md:max-w-full;
}
.ui-autocomplete .ui-menu-item {
  @apply block border-b border-spring-wood;
  @apply first:mt-3;
}
.ui-autocomplete .ui-menu-item a.suggest-item {
  @apply block p-3 md:px-4 bg-white transition-all;
  @apply hover:underline hover:bg-spring-wood;
  @apply focus:underline focus:bg-spring-wood;
}

.accordian-item {
  border-radius: 2rem;
  @apply mb-4 last:mb-0 block transition-all;
}

.accordian-item__title {
  @apply relative rounded-full font-medium bg-white select-none appearance-none shadow-accordian-item py-4 px-7 pr-16 md:pr-16 md:py-5 md:px-14;
}

.accordian-item__opener-icon {
  @apply absolute top-1/2 -translate-y-1/2 right-9 fill-current transition-all;
}

.accordian-item__content {
  @apply hidden text-base px-6 py-5 md:px-12 md:py-10 overflow-hidden max-w-full font-normal;
}

.accordian-item[open] {
  @apply bg-white shadow-accordian-item;
}

.accordian-item[open] .accordian-item__title {
  @apply text-primary;
}

.accordian-item[open] .accordian-item__opener-icon {
  @apply scale-flip-y;
}

.accordian-item[open] .accordian-item__content {
  @apply block;
}

.product-item {
  @apply relative overflow-hidden flex flex-col text-left;
}
.product-item .media-item {
  @apply w-full h-0 relative pt-image-height bg-black bg-opacity-25;
}
.product-item .media-item__thumbnail {
  @apply absolute inset-0 w-full h-full object-cover;
}
.product-item .media-item__attachments {
  @apply absolute bottom-0 right-6 translate-y-2/4;
}
.product-item .media-item__attachments .supplier-flag {
  @apply mr-6px;
}
.product-item .rating-btn {
  @apply absolute right-3 top-2 bg-white;
}
.product-item .text-list > p {
  @apply hidden first:block text-base w-full mb-4 line-clamp-1;
}
.product-item__price .price-list .articleitem.price {
  @apply hidden;
}
.product-item__price .price-list .articleitem.price.price-actual {
  @apply block;
}
.product-item__add-to-basket {
  @apply flex w-cart-form-width pt-4;
}
.product-item__add-to-basket .input-group {
  @apply relative;
}
.product-item__add-to-basket input {
  @apply w-full h-cart-button rounded-full border-akaroa pr-10 underline;
}
.product-item__add-to-basket button {
  @apply absolute bottom-0 right-0 w-cart-button h-cart-button p-2 text-center bg-akaroa text-black text-none;
  @apply transition-opacity hover:opacity-80 focus:opacity-80;
}
.product-item .catalog-detail-basket__button-wrapper .btn-prebooking {
  @apply text-xs px-4 max-w-full;
  @apply md:text-base md:px-7;
}

.aimeos-container.aimeos {
  @apply fixed inset-0 flex justify-center items-center z-50 p-16;
  @apply lg:top-header-height-desktop;
  top: 0 !important;
}
.aimeos-container.aimeos .basket-standard__bg {
  @apply hidden;
}
.aimeos-container.aimeos .basket-standard,
.aimeos-container.aimeos .account-watch,
.aimeos-container.aimeos .catalog-list {
  @apply bg-white h-full p-8 overflow-y-auto container;
}
.aimeos-container.aimeos .basket-standard__inner,
.aimeos-container.aimeos .account-watch__inner,
.aimeos-container.aimeos .catalog-list__inner {
  @apply p-0 shadow-none relative;
}
.aimeos-container.aimeos .aimeos-container__closer {
  @apply block absolute right-0 top-2;
}
.aimeos-container.aimeos .catalog-list {
  @apply p-0;
}
.aimeos-container.aimeos > .minibutton {
  @apply hidden;
}
.aimeos-container.aimeos .account-watch__title {
  @apply text-center border-b border-white-rock pb-4;
}
.aimeos-container.aimeos .account-watch .watch-items {
  @apply lg:grid-cols-3;
}

.aimeos-overlay {
  @apply fixed inset-0 bg-black z-50;
  opacity: 0.5 !important;
}

.rating-btn {
  @apply hidden;
}

.supplier-flag {
  @apply inline-flex p-2 justify-center items-center bg-white text-roman border border-white-rock transition-all rounded-full w-action-btn-size h-action-btn-size overflow-hidden mr-4;
}
.supplier-flag img {
  @apply w-full h-auto;
}

.actions-button-watch {
  @apply flex justify-center items-center bg-white text-roman border border-white-rock transition-all rounded-full w-action-btn-size h-action-btn-size overflow-hidden;
  @apply hover:border-roman;
  @apply focus:border-roman;
}
.actions-button-watch, .actions-button-favorite, .actions-button-pin {
  @apply hidden;
}

.price-list .articleitem.price .price-label--actual {
  @apply hidden;
}
.price-list .articleitem.price:only-child .price-label--actual {
  @apply inline;
}
.price-list .articleitem.price:only-child .price-label--starting {
  @apply hidden;
}

.editor-data img,
.editor-data video,
.editor-data iframe {
  @apply max-w-full w-auto h-auto;
}
.editor-data strong,
.editor-data b {
  @apply font-medium font-bold;
}
.editor-data em {
  @apply italic;
}
.editor-data h1 {
  @apply block text-2xl md:text-4xl mb-4;
}
.editor-data h2 {
  @apply block text-xl md:text-2xl mb-3;
}
.editor-data h3 {
  @apply block text-lg mb-2;
}
.editor-data h4 {
  @apply block text-base mb-1;
}
.editor-data h5 {
  @apply block text-sm mb-1;
}
.editor-data p,
.editor-data ul,
.editor-data ol {
  @apply my-3;
}
.editor-data p:first-of-type,
.editor-data ul:first-of-type,
.editor-data ol:first-of-type {
  @apply mt-0;
}
.editor-data ul {
  @apply list-disc list-inside;
  @apply pl-4;
}
.editor-data ol {
  @apply list-decimal list-inside;
  @apply pl-4;
}

.site-cookiebar {
  @apply transition-all duration-300 opacity-0 invisible;
}
.site-cookiebar a {
  @apply underline text-primary;
  @apply hover:no-underline;
  @apply focus:no-underline;
}
.site-cookiebar.is-visible {
  @apply opacity-100 visible;
}

.form-list {
  @apply pt-4 pb-6;
  @apply md:flex md:flex-wrap md:-mx-1.5;
}
.form-item {
  @apply py-2 md:w-1/2 md:px-1.5;
}
.form-item select,
.form-item input[type=text],
.form-item input[type=tel],
.form-item input[type=date],
.form-item input[type=datetime-local],
.form-item input[type=month],
.form-item input[type=number],
.form-item input[type=url],
.form-item input[type=time],
.form-item input[type=week],
.form-item input[type=email] {
  @apply w-full rounded-full border-white-rock py-3 px-6 font-light text-base;
  @apply md:py-4 px-8;
}
.form-item input[pattern] {
  @apply invalid:text-red-600;
}
.form-item.store label, .form-item.show-label label {
  @apply block font-medium text-primary pl-4;
}
.form-item.store input[type=checkbox],
.form-item.store input[type=radio], .form-item.show-label input[type=checkbox],
.form-item.show-label input[type=radio] {
  @apply ml-4;
}
.form-item:not(.store) label, .form-item:not(.show-label) label {
  @apply sr-only;
}
.form-item.error input {
  @apply border-red-600 text-red-600;
}

.checkout-standard-address .form-list,
.checkout-standard-delivery .form-list,
.checkout-standard-payment .form-list,
.checkout-standard-summary .form-list,
.checkout-standard-process .form-list,
.account-template__wrapper .account-profile .form-list,
.account-template__wrapper .account-change-password .form-list {
  @apply pt-4 pb-6;
  @apply md:flex md:flex-wrap md:-mx-1.5;
}
.checkout-standard-address .form-item,
.checkout-standard-delivery .form-item,
.checkout-standard-payment .form-item,
.checkout-standard-summary .form-item,
.checkout-standard-process .form-item,
.account-template__wrapper .account-profile .form-item,
.account-template__wrapper .account-change-password .form-item {
  @apply py-2 md:w-1/2 md:px-1.5;
}
.checkout-standard-address .form-item select,
.checkout-standard-address .form-item input[type=text],
.checkout-standard-address .form-item input[type=tel],
.checkout-standard-address .form-item input[type=date],
.checkout-standard-address .form-item input[type=datetime-local],
.checkout-standard-address .form-item input[type=month],
.checkout-standard-address .form-item input[type=number],
.checkout-standard-address .form-item input[type=url],
.checkout-standard-address .form-item input[type=time],
.checkout-standard-address .form-item input[type=password],
.checkout-standard-address .form-item input[type=week],
.checkout-standard-address .form-item input[type=email],
.checkout-standard-delivery .form-item select,
.checkout-standard-delivery .form-item input[type=text],
.checkout-standard-delivery .form-item input[type=tel],
.checkout-standard-delivery .form-item input[type=date],
.checkout-standard-delivery .form-item input[type=datetime-local],
.checkout-standard-delivery .form-item input[type=month],
.checkout-standard-delivery .form-item input[type=number],
.checkout-standard-delivery .form-item input[type=url],
.checkout-standard-delivery .form-item input[type=time],
.checkout-standard-delivery .form-item input[type=password],
.checkout-standard-delivery .form-item input[type=week],
.checkout-standard-delivery .form-item input[type=email],
.checkout-standard-payment .form-item select,
.checkout-standard-payment .form-item input[type=text],
.checkout-standard-payment .form-item input[type=tel],
.checkout-standard-payment .form-item input[type=date],
.checkout-standard-payment .form-item input[type=datetime-local],
.checkout-standard-payment .form-item input[type=month],
.checkout-standard-payment .form-item input[type=number],
.checkout-standard-payment .form-item input[type=url],
.checkout-standard-payment .form-item input[type=time],
.checkout-standard-payment .form-item input[type=password],
.checkout-standard-payment .form-item input[type=week],
.checkout-standard-payment .form-item input[type=email],
.checkout-standard-summary .form-item select,
.checkout-standard-summary .form-item input[type=text],
.checkout-standard-summary .form-item input[type=tel],
.checkout-standard-summary .form-item input[type=date],
.checkout-standard-summary .form-item input[type=datetime-local],
.checkout-standard-summary .form-item input[type=month],
.checkout-standard-summary .form-item input[type=number],
.checkout-standard-summary .form-item input[type=url],
.checkout-standard-summary .form-item input[type=time],
.checkout-standard-summary .form-item input[type=password],
.checkout-standard-summary .form-item input[type=week],
.checkout-standard-summary .form-item input[type=email],
.checkout-standard-process .form-item select,
.checkout-standard-process .form-item input[type=text],
.checkout-standard-process .form-item input[type=tel],
.checkout-standard-process .form-item input[type=date],
.checkout-standard-process .form-item input[type=datetime-local],
.checkout-standard-process .form-item input[type=month],
.checkout-standard-process .form-item input[type=number],
.checkout-standard-process .form-item input[type=url],
.checkout-standard-process .form-item input[type=time],
.checkout-standard-process .form-item input[type=password],
.checkout-standard-process .form-item input[type=week],
.checkout-standard-process .form-item input[type=email],
.account-template__wrapper .account-profile .form-item select,
.account-template__wrapper .account-profile .form-item input[type=text],
.account-template__wrapper .account-profile .form-item input[type=tel],
.account-template__wrapper .account-profile .form-item input[type=date],
.account-template__wrapper .account-profile .form-item input[type=datetime-local],
.account-template__wrapper .account-profile .form-item input[type=month],
.account-template__wrapper .account-profile .form-item input[type=number],
.account-template__wrapper .account-profile .form-item input[type=url],
.account-template__wrapper .account-profile .form-item input[type=time],
.account-template__wrapper .account-profile .form-item input[type=password],
.account-template__wrapper .account-profile .form-item input[type=week],
.account-template__wrapper .account-profile .form-item input[type=email],
.account-template__wrapper .account-change-password .form-item select,
.account-template__wrapper .account-change-password .form-item input[type=text],
.account-template__wrapper .account-change-password .form-item input[type=tel],
.account-template__wrapper .account-change-password .form-item input[type=date],
.account-template__wrapper .account-change-password .form-item input[type=datetime-local],
.account-template__wrapper .account-change-password .form-item input[type=month],
.account-template__wrapper .account-change-password .form-item input[type=number],
.account-template__wrapper .account-change-password .form-item input[type=url],
.account-template__wrapper .account-change-password .form-item input[type=time],
.account-template__wrapper .account-change-password .form-item input[type=password],
.account-template__wrapper .account-change-password .form-item input[type=week],
.account-template__wrapper .account-change-password .form-item input[type=email] {
  @apply w-full rounded-full border-white-rock py-3 px-6 font-light text-base;
  @apply md:py-4 px-8;
}
.checkout-standard-address .form-item.store label, .checkout-standard-address .form-item.show-label label,
.checkout-standard-delivery .form-item.store label,
.checkout-standard-delivery .form-item.show-label label,
.checkout-standard-payment .form-item.store label,
.checkout-standard-payment .form-item.show-label label,
.checkout-standard-summary .form-item.store label,
.checkout-standard-summary .form-item.show-label label,
.checkout-standard-process .form-item.store label,
.checkout-standard-process .form-item.show-label label,
.account-template__wrapper .account-profile .form-item.store label,
.account-template__wrapper .account-profile .form-item.show-label label,
.account-template__wrapper .account-change-password .form-item.store label,
.account-template__wrapper .account-change-password .form-item.show-label label {
  @apply block pl-4;
}
.checkout-standard-address .form-item.store input[type=checkbox],
.checkout-standard-address .form-item.store input[type=radio], .checkout-standard-address .form-item.show-label input[type=checkbox],
.checkout-standard-address .form-item.show-label input[type=radio],
.checkout-standard-delivery .form-item.store input[type=checkbox],
.checkout-standard-delivery .form-item.store input[type=radio],
.checkout-standard-delivery .form-item.show-label input[type=checkbox],
.checkout-standard-delivery .form-item.show-label input[type=radio],
.checkout-standard-payment .form-item.store input[type=checkbox],
.checkout-standard-payment .form-item.store input[type=radio],
.checkout-standard-payment .form-item.show-label input[type=checkbox],
.checkout-standard-payment .form-item.show-label input[type=radio],
.checkout-standard-summary .form-item.store input[type=checkbox],
.checkout-standard-summary .form-item.store input[type=radio],
.checkout-standard-summary .form-item.show-label input[type=checkbox],
.checkout-standard-summary .form-item.show-label input[type=radio],
.checkout-standard-process .form-item.store input[type=checkbox],
.checkout-standard-process .form-item.store input[type=radio],
.checkout-standard-process .form-item.show-label input[type=checkbox],
.checkout-standard-process .form-item.show-label input[type=radio],
.account-template__wrapper .account-profile .form-item.store input[type=checkbox],
.account-template__wrapper .account-profile .form-item.store input[type=radio],
.account-template__wrapper .account-profile .form-item.show-label input[type=checkbox],
.account-template__wrapper .account-profile .form-item.show-label input[type=radio],
.account-template__wrapper .account-change-password .form-item.store input[type=checkbox],
.account-template__wrapper .account-change-password .form-item.store input[type=radio],
.account-template__wrapper .account-change-password .form-item.show-label input[type=checkbox],
.account-template__wrapper .account-change-password .form-item.show-label input[type=radio] {
  @apply ml-3 text-primary;
}
.checkout-standard-address .form-item:not(.store) label, .checkout-standard-address .form-item:not(.show-label) label,
.checkout-standard-delivery .form-item:not(.store) label,
.checkout-standard-delivery .form-item:not(.show-label) label,
.checkout-standard-payment .form-item:not(.store) label,
.checkout-standard-payment .form-item:not(.show-label) label,
.checkout-standard-summary .form-item:not(.store) label,
.checkout-standard-summary .form-item:not(.show-label) label,
.checkout-standard-process .form-item:not(.store) label,
.checkout-standard-process .form-item:not(.show-label) label,
.account-template__wrapper .account-profile .form-item:not(.store) label,
.account-template__wrapper .account-profile .form-item:not(.show-label) label,
.account-template__wrapper .account-change-password .form-item:not(.store) label,
.account-template__wrapper .account-change-password .form-item:not(.show-label) label {
  @apply sr-only;
}
.checkout-standard-address .form-item--checkbox .col-md-7,
.checkout-standard-delivery .form-item--checkbox .col-md-7,
.checkout-standard-payment .form-item--checkbox .col-md-7,
.checkout-standard-summary .form-item--checkbox .col-md-7,
.checkout-standard-process .form-item--checkbox .col-md-7,
.account-template__wrapper .account-profile .form-item--checkbox .col-md-7,
.account-template__wrapper .account-change-password .form-item--checkbox .col-md-7 {
  @apply flex items-center;
}
.checkout-standard-address .form-item--checkbox .col-md-7 label,
.checkout-standard-delivery .form-item--checkbox .col-md-7 label,
.checkout-standard-payment .form-item--checkbox .col-md-7 label,
.checkout-standard-summary .form-item--checkbox .col-md-7 label,
.checkout-standard-process .form-item--checkbox .col-md-7 label,
.account-template__wrapper .account-profile .form-item--checkbox .col-md-7 label,
.account-template__wrapper .account-change-password .form-item--checkbox .col-md-7 label {
  @apply pl-2;
}
.checkout-standard-address__button-group,
.checkout-standard-delivery__button-group,
.checkout-standard-payment__button-group,
.checkout-standard-summary__button-group,
.checkout-standard-process__button-group,
.account-template__wrapper .account-profile__button-group,
.account-template__wrapper .account-change-password__button-group {
  @apply flex justify-center flex-col py-6 border-t border-white-rock md:pt-12;
}
.checkout-standard-address__button-group .item,
.checkout-standard-address__button-group .btn,
.checkout-standard-delivery__button-group .item,
.checkout-standard-delivery__button-group .btn,
.checkout-standard-payment__button-group .item,
.checkout-standard-payment__button-group .btn,
.checkout-standard-summary__button-group .item,
.checkout-standard-summary__button-group .btn,
.checkout-standard-process__button-group .item,
.checkout-standard-process__button-group .btn,
.account-template__wrapper .account-profile__button-group .item,
.account-template__wrapper .account-profile__button-group .btn,
.account-template__wrapper .account-change-password__button-group .item,
.account-template__wrapper .account-change-password__button-group .btn {
  @apply inline-block mx-auto mb-2 last:mb-0;
}
.checkout-standard-address__button-group .link,
.checkout-standard-delivery__button-group .link,
.checkout-standard-payment__button-group .link,
.checkout-standard-summary__button-group .link,
.checkout-standard-process__button-group .link,
.account-template__wrapper .account-profile__button-group .link,
.account-template__wrapper .account-change-password__button-group .link {
  @apply inline-flex items-center underline text-primary;
  @apply hover:no-underline;
  @apply focus:no-underline;
}
.checkout-standard-address__button-group .link .icon,
.checkout-standard-delivery__button-group .link .icon,
.checkout-standard-payment__button-group .link .icon,
.checkout-standard-summary__button-group .link .icon,
.checkout-standard-process__button-group .link .icon,
.account-template__wrapper .account-profile__button-group .link .icon,
.account-template__wrapper .account-change-password__button-group .link .icon {
  @apply mr-1 leading-none;
}

.toast-items {
  @apply fixed inset-x-0 px-6 flex flex-col justify-center gap-3 z-80 -top-full transition-top duration-300;
}
.toast-items__item {
  @apply hidden mx-auto font-medium p-3 bg-white drop-shadow-xl rounded-2xl shadow-inner border-2 px-4;
  @apply md:p-2 md:px-8;
}
.toast-items__item.active {
  @apply block;
}
.toast-items.active {
  @apply top-[20%];
}

.error-item {
  @apply text-red-500 font-medium mb-2;
}

.block-heading__title {
  @apply text-2xl mb-2 md:text-46px md:mb-4;
}
.block-heading__title--normal {
  @apply text-lg text-opacity-50 text-black;
}
.block-heading__title--medium {
  @apply block text-34px text-left mb-4;
  @apply md:text-center md:mb-8;
}
.block-heading__title--small {
  @apply text-2xl font-serif font-bold mb-2;
}

.site-footer__input-group,
.newsletter-block__input-group {
  @apply relative mb-2;
}
.site-footer__input-group input[type=email],
.newsletter-block__input-group input[type=email] {
  @apply w-full h-12 px-6 py-4 pr-32 text-base bg-spring-wood border border-gray-300 rounded-full focus:outline-none focus:border-gray-500;
}
.site-footer__input-group button,
.newsletter-block__input-group button {
  @apply absolute inset-y-0 right-0;
}
.site-footer__input-group--consent,
.newsletter-block__input-group--consent {
  @apply pt-2 inline-flex items-center;
}
.site-footer__input-group--consent label,
.newsletter-block__input-group--consent label {
  @apply ml-2;
}

.site-header {
  @apply fixed;
  @apply inset-x-0;
  @apply top-0;
  @apply z-40;
}
.site-header__wrapper {
  @apply relative z-10 shadow-header-shadow;
}
.site-header__main {
  @apply bg-white-rock;
}

.site-header a {
  @apply font-medium;
  @apply text-sm;
  @apply hover:underline;
  @apply focus:underline;
}

.site-header__button {
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply h-header-mobile-height;
  @apply px-4;
  @apply bg-transparent;
  @apply transition-all;
  @apply focus:opacity-80;
  @apply lg:h-header-desktop-height;
  @apply lg:px-5;
  @apply hover:bg-opacity-75;
}
.site-header__button--search {
  @apply bg-sand;
}

.site-header a {
  @apply md:text-base;
}

.navbar-wrapper {
  @apply fixed inset-0 bg-spring-wood top-header-height invisible opacity-0 transition-all;
  @apply w-full h-full flex justify-center md:justify-start items-center md:items-start lg:top-header-height-desktop;
  @apply xl:bg-transparent xl:transform-none xl:static xl:w-auto xl:h-auto xl:inline-flex xl:flex-1 xl:visible xl:opacity-100 xl:justify-center;
}
.js-nav-active .navbar-wrapper {
  @apply opacity-100 visible z-10;
}
.navbar__list {
  @apply flex flex-col w-full h-full mt-2;
  @apply xl:flex-row xl:w-auto xl:h-auto xl:mt-0;
}
.navbar__item {
  @apply font-medium mb-2;
  @apply xl:px-5 xl:mb-0;
  @apply md:first:pl-0;
  @apply md:last:pr-0;
}
.navbar__item--auth {
  @apply xl:border-l xl:border-black;
}
.navbar__item-link {
  @apply font-medium;
  @apply hover:text-primary;
}
.navbar__item.active {
  @apply text-primary;
}
.navbar__opener {
  @apply relative inline-flex flex-col -mr-4;
  @apply hover:opacity-100 focus:opacity-100;
}
.navbar__opener--dash {
  @apply relative w-5 h-0.2 bg-black mb-1 transition-transform duration-300 ease-in-out;
  @apply last:mb-0;
}
.navbar__opener--dash:nth-child(2) {
  @apply transition-none;
}
.js-nav-active .navbar__opener--dash:first-child {
  @apply transform rotate-45;
  top: 0.375rem;
}
.js-nav-active .navbar__opener--dash:nth-child(2) {
  @apply opacity-0;
}
.js-nav-active .navbar__opener--dash:last-child {
  @apply transform -rotate-45;
  top: -0.375rem;
}

.breadcrumb__list {
  @apply flex;
}

.products-listing .list-items {
  @apply md:grid-cols-2;
  @apply lg:grid-cols-3;
  @apply px-0;
}

.product-list__items .container {
  @apply px-0;
}
.product-list__items .list-search {
  @apply font-serif font-medium text-19px mb-4 text-mine-shaft text-opacity-50;
}
.product-list__items .list-search .searchstring {
  @apply text-mine-shaft text-opacity-75 font-bold;
}

.catalog-list--supplier .pagination {
  @apply hidden;
}

.site-footer {
  @apply pt-16 font-normal;
}
.site-footer__logo {
  @apply flex justify-center pb-10 lg:pb-20;
}
.site-footer__logo-img {
  @apply w-footer-logo lg:w-footer-logo-desktop;
}
.site-footer__title {
  @apply font-bold block mb-2 md:mb-3;
}
.site-footer__info {
  @apply block;
}
.site-footer__info a {
  @apply inline-block underline break-all;
  @apply hover:text-primary hover:no-underline;
}
.site-footer__row {
  @apply text-center md:text-left md:flex md:justify-between md:flex-wrap lg:flex-nowrap pb-8 md:pb-16;
}
.site-footer__row-item {
  @apply w-full md:w-2/4 md:pr-10 lg:pr-20 lg:last:pr-0 pb-8 lg:pb-0;
}
.site-footer__row-item--nav {
  @apply lg:max-w-2/10;
}
.site-footer__row-item--address {
  @apply lg:max-w-3/10;
}
.site-footer__row-item--newsletter {
  @apply lg:flex-1;
}
.site-footer__row-item--social-nav {
  @apply lg:max-w-150px lg:text-right;
}
.site-footer__nav-list {
  @apply flex flex-col;
}
.site-footer__nav-list--social {
  @apply flex flex-row justify-center md:justify-start lg:justify-end pt-6;
}
.site-footer__nav-list--social li {
  @apply px-2 last:pr-0 first:pl-0;
}
.site-footer__nav-item {
  @apply mb-3 last:mb-0;
}
.site-footer__nav-item-link {
  @apply font-medium;
  @apply hover:underline;
}
.site-footer__select-lang {
  @apply mb-4;
}
.site-footer__select-lang select {
  @apply bg-transparent border-none m-0 pr-8 py-0 underline;
}
.site-footer__newsletter-form {
  @apply block mt-6;
}
.site-footer__newsletter-form label {
  @apply pl-2;
}
.site-footer__copyright {
  @apply bg-black py-4;
}
.site-footer__copyright-wrapper {
  @apply flex flex-col lg:flex-row text-white justify-between items-center;
}
.site-footer__logo-list {
  @apply flex flex-wrap justify-center mb-8 px-4 order-1;
  @apply lg:order-none lg:mb-0;
}
.site-footer__logo-item {
  @apply mx-4 my-2 max-w-66px first:ml-0 last:mr-0;
}
.site-footer__logo-item img {
  @apply w-full;
}
.site-footer__copyright-text {
  @apply text-white-rock text-base;
  @apply order-2 lg:order-none;
}
.site-footer__pigment-text {
  @apply order-3 lg:order-none;
}
.site-footer__pigment-text a {
  @apply opacity-40 text-xs;
}

.product-banner,
.banner-block {
  @apply w-full block relative z-10 min-h-single-product-banner md:min-h-single-product-banner-large;
}
.product-banner__container,
.banner-block__container {
  @apply relative z-20 flex h-full;
}
.product-banner__container:not(:empty) .product-banner__inner,
.banner-block__container:not(:empty) .product-banner__inner {
  @apply w-full md:-mb-7;
}
.product-banner__inner,
.banner-block__inner {
  @apply bg-white md:max-w-xl pb-10 py-7 px-6 md:p-16 shadow-md;
}
.product-banner__inner-btn,
.banner-block__inner-btn {
  @apply pt-5 md:pt-10;
}
.product-banner__title,
.banner-block__title {
  @apply text-3xl md:text-56px mb-4;
}
.product-banner__btn,
.banner-block__btn {
  @apply absolute right-5 -bottom-7 md:bottom-6 z-20;
}
.product-banner__btn-icon,
.banner-block__btn-icon {
  @apply w-7 md:mr-3 md:-ml-2;
}
.product-banner__bg-image,
.banner-block__bg-image {
  @apply absolute inset-0 w-full h-full object-cover z-10;
}
.product-banner--has-content,
.banner-block--has-content {
  @apply md:mb-7;
}
.product-banner--has-content .container,
.banner-block--has-content .container {
  @apply px-0 md:px-4 xl:max-w-banner-container;
}
.product-banner--has-content .product-banner__bg-image,
.product-banner--has-content .banner-block__bg-image,
.banner-block--has-content .product-banner__bg-image,
.banner-block--has-content .banner-block__bg-image {
  @apply max-h-48 relative md:absolute md:max-h-full;
}

.site-header__button--cart {
  @apply animate-pulse;
}
.site-header__button--cart-icon {
  @apply relative -top-0.5;
}
.site-header__button--cart--total-quantity {
  @apply block pl-2 min-w-[30px];
}
.site-header__button--cart.basket-counted {
  @apply animate-none;
}

.basket-mini {
  @apply fixed z-35 right-0 top-header-height bottom-0 w-full h-full flex justify-end opacity-0 invisible transition-all;
  @apply lg:top-header-height-desktop;
}
.js-basket-active .basket-mini {
  @apply visible opacity-100;
}
.basket-mini__header {
  @apply block text-34px text-center mb-0;
}
.basket-mini__content {
  @apply w-full h-full bg-white px-8 pt-8 pb-24 shadow-lg relative z-30 min-h-full overflow-y-auto md:pt-10 md:-right-10 transition-all;
  @apply md:w-basket-mini-width;
}
.js-basket-active .basket-mini__content {
  @apply md:right-0;
}
.basket-mini__content table {
  @apply w-full text-left my-7;
}
.basket-mini__content table thead {
  @apply border-t border-b border-white-rock;
}
.basket-mini__content table th {
  @apply py-3 font-light;
}
.basket-mini__content table tbody {
  @apply border-b border-white-rock;
}
.basket-mini__content table td {
  @apply font-medium py-2 px-2;
}
.basket-mini__content table th:not(.name), .basket-mini__content table td:not(.name) {
  @apply text-right;
}
.basket-mini__closer {
  @apply sticky top-0 w-12 h-12 rounded-full inline-flex items-center justify-center bg-white transition-opacity;
  @apply hover:opacity-80;
}
.basket-mini-product {
  @apply pb-20;
}
.basket-mini__total {
  @apply pb-3 text-right block border-b border-white-rock;
}
.basket-mini__total-item {
  @apply mb-1 last:mb-0;
}
.basket-mini__total--grand .value {
  @apply font-medium;
}
.basket-mini__button-wrapper {
  @apply flex flex-wrap justify-center py-8;
}
.basket-mini__button-wrapper .info {
  @apply block mt-4 text-dusty-gray;
}
.basket-mini__overlay {
  @apply fixed z-20 top-0 left-0 right-0 bottom-0 bg-black opacity-50;
}

.basket-standard {
  @apply relative overflow-hidden md:pb-8;
}
.basket-standard .aimeos-container__closer {
  @apply hidden;
}
.basket-standard__container {
  @apply relative z-20;
}
.basket-standard__inner {
  @apply py-10 relative md:px-60px lg:pt-16 md:bg-white md:shadow-lg;
}
.basket-standard__title {
  @apply block text-34px text-left mb-4;
  @apply md:text-center md:mb-8;
}
.basket-standard__row {
  @apply pt-6 md:flex md:justify-between md:items-end;
}
.basket-standard__row .input-group {
  @apply relative w-full md:w-80;
}
.basket-standard__row .input-group input {
  @apply w-full rounded-full border bg-none pr-20 border-white-rock;
}
.basket-standard__row .input-group .btn {
  @apply absolute inset-y-0 right-0;
}
.basket-standard__row .basket-standard-coupon h2 {
  @apply font-medium font-sans text-base mb-2;
}
.basket-standard__row .basket-standard-coupon__list {
  @apply flex mb-2;
}
.basket-standard__row .basket-standard-coupon__list-item {
  @apply inline-flex items-center bg-primary text-white text-center px-4 py-1.5 mr-2 rounded-full;
}
.basket-standard__row .basket-standard-coupon__list-item .icon {
  @apply ml-2 relative leading-none;
}
.basket-standard__row .basket-standard-coupon__list-item .delete {
  @apply text-white;
  @apply hover:text-opacity-75;
  @apply focus:text-opacity-75;
}
.basket-standard__row .button-group {
  @apply pt-3;
}
.basket-standard__bg {
  @apply absolute inset-0 h-full w-full z-10;
}
.basket-standard__bg-image {
  @apply object-cover object-center w-full h-full hidden md:block;
}

.basket-mini table td.action,
.basket-standard table td.action {
  @apply w-8 text-right;
}
.basket-mini table td.action .delete,
.basket-standard table td.action .delete {
  @apply inline-flex;
}
.basket-mini table td.action .delete--icon,
.basket-standard table td.action .delete--icon {
  @apply inline-block;
}

.product-list {
  @apply md:flex md:pt-8 pb-16 md:pb-32;
}
.product-list__sidebar {
  @apply w-full p-4 bg-white-rock mb-6;
  @apply md:p-7 md:mb-auto md:max-w-filter-sidebar;
}
.product-list__sidebar h1, .product-list__sidebar h2 {
  @apply font-bold text-2xl pb-4 border-b border-akaroa mb-4;
}
.product-list__sidebar .btn.supplier-selected,
.product-list__sidebar .item-img__featured,
.product-list__sidebar .cat-item__image,
.product-list__sidebar .shop-list__link,
.product-list__sidebar .shop-list__description,
.product-list__sidebar .submenu,
.product-list__sidebar .submenu .item-link div.media-list {
  @apply hidden;
}
.product-list__sidebar .attr-list {
  @apply mb-4;
}
.product-list__sidebar .top-item.active > .item-links .cat-item__description .item-link {
  @apply text-roman;
}
.product-list__sidebar .top-item.active .submenu {
  @apply block;
}
.product-list__sidebar .top-item .submenu {
  @apply pl-3 mb-3 hidden;
}
.product-list__sidebar .top-item .level-2 .item-links {
  @apply hidden;
}
.product-list__sidebar .cat-item__title {
  @apply flex items-center;
}
.product-list__sidebar .cat-item__title .item-link__submenu-opener {
  @apply block mb-2 ml-auto cursor-pointer w-3.5 min-h-full overflow-hidden;
  @apply hover:text-roman;
}
.product-list__sidebar .item-link {
  @apply inline-flex items-center mb-2 font-medium;
  @apply hover:text-roman;
}
.product-list__sidebar .item-link__icon, .product-list__sidebar .item-link .media-list {
  @apply mr-3 max-w-22px hidden first:inline-flex;
}
.product-list__sidebar .item-link__icon img, .product-list__sidebar .item-link .media-list img {
  @apply w-full h-auto;
}
.product-list__sidebar .item-link.active {
  @apply text-roman;
}
.product-list__items {
  @apply w-full;
  @apply md:flex-1 md:pl-4 md:ml-0;
}
.product-list__items .pagination,
.product-list__items .catalog-list-head,
.product-list__items .menu-head,
.product-list__items .top-item.nochild,
.product-list__items .has-submenu .item-links,
.product-list__items .top-item .submenu,
.product-list__items .cat-item__link .media-list,
.product-list__items .item-link__icon,
.product-list__items .item-link .media-list,
.product-list__items .js-subcategory-opener {
  @apply hidden;
}
.product-list__items .top-item.active .submenu {
  @apply block;
}
.product-list__items .top-item.active .submenu .list-container {
  @apply grid grid-cols-2 lg:grid-cols-3 gap-4;
}
.product-list__items .top-item.active .submenu .list-container > .cat-item {
  @apply bg-white shadow-card-item;
}
.product-list__items .list-items {
  @apply md:grid-cols-2 px-0;
  @apply lg:grid-cols-3;
}
.product-list__items .list-items .product .articleitem {
  @apply hidden;
}
.product-list__items .list-items .product .articleitem.stock-actual {
  @apply block;
}
.product-list__items .cat-item__image {
  @apply relative;
}
.product-list__items .cat-item__image-flag {
  @apply absolute left-4 bottom-0 translate-y-1/2;
}
.product-list__items .cat-item__description {
  @apply text-center justify-center flex flex-col py-8 px-6;
}
.product-list__items .cat-item__description a {
  @apply inline-flex mx-auto text-center;
}
.product-list__items .cat-name,
.product-list__items .item-link__label {
  @apply text-center text-lg font-serif font-bold mb-2 break-words;
  @apply md:text-2xl;
}
.product-list__items--list .top-item,
.product-list__items--list .top-item.nochild,
.product-list__items--list .has-submenu .item-links {
  @apply block;
}
.product-list__items--list .list-container {
  @apply grid grid-cols-2 gap-4;
  @apply md:flex md:flex-wrap md:justify-center;
  @apply md:-mx-4;
}
.product-list__items--list .list-container .cat-item {
  @apply w-full;
  @apply md:w-46%;
  @apply lg:w-3/10 lg:max-w-1/3;
}
.product-list__items--list .list-container > .cat-item {
  @apply bg-white shadow-card-item;
}
.product-list .pagination,
.product-list a.btn.supplier-selected,
.product-list a.btn.category-selected,
.product-list .catalog-list-head,
.product-list div.attribute-selected,
.product-list .attr-count {
  @apply hidden;
}

.product-filter {
  @apply py-7;
}
.product-filter__heading {
  @apply text-center pb-8;
}
.product-filter__heading-title {
  @apply text-2xl md:text-46px mb-2 md:mb-4;
}
.product-filter__filter {
  @apply text-center pb-3 mb-12 border-b border-white-rock;
}
.product-filter__filter h1,
.product-filter__filter h2,
.product-filter__filter .attr-Varutyp legend {
  @apply font-serif font-bold text-19px opacity-50 mb-3;
}
.product-filter__filter .attr-list {
  @apply flex flex-wrap justify-center pb-8;
}
.product-filter__filter .attr-list .attr-item input[type=checkbox] {
  @apply sr-only;
}
.product-filter__filter .attr-list .attr-item input[type=checkbox]:checked + label {
  @apply bg-white-rock;
}
.product-filter__filter .attr-list .attr-item label {
  @apply flex items-center justify-center px-5 py-2 mb-3 mx-1.5 bg-white rounded-full border border-white-rock p-4 font-medium cursor-pointer transition;
  @apply hover:bg-white-rock;
  @apply focus:bg-white-rock;
}
.product-filter__filter .attr-list .attr-item .icon {
  @apply mr-3;
}
.product-filter__filter .attr-list .attr-item .flag {
  @apply hidden;
}
.product-filter__filter .attr-list .attr-item .media-list {
  @apply hidden;
}
.product-filter__filter .attr-list .attr-item .attr-count {
  @apply sr-only;
}
.product-filter__filter .attr-list .attr-item.disabled input:checked + label {
  @apply opacity-100;
}
.product-filter__filter .attr-Land legend,
.product-filter__filter .attr-download legend,
.product-filter__filter .catalog-list-footer {
  @apply hidden;
}
.product-filter__filter .attribute-lists .attr-Varutyp,
.product-filter__filter .attribute-lists .attr-Varutyp .attr-list {
  display: flex !important;
}
.product-filter__filter--options .attr-list .attr-item .media-list {
  @apply inline-flex;
}
.product-filter__filter--options .attr-list .attr-item .media-list .media-item {
  @apply mr-3 hidden first:inline-flex;
}
.product-filter__filter--options .attr-list .attr-item .media-list img {
  @apply block max-w-22px w-full h-auto;
}
.product-filter .catalog-list-items .stock-list .articleitem {
  @apply hidden;
}
.product-filter .catalog-list-items .stock-list .articleitem:only-child.stock-actual {
  @apply block;
}
.product-filter .pagination,
.product-filter a.btn.supplier-selected,
.product-filter .catalog-list-head,
.product-filter .catalog-list-footer,
.product-filter div.attribute-selected {
  @apply hidden;
}

.product__page-image {
  @apply overflow-hidden;
}

.product-catalog__info .product-catalog__inner {
  @apply relative overflow-hidden;
}
.product-catalog__info .articleitem.price {
  @apply hidden;
}
.product-catalog__info .articleitem.price.price-actual {
  @apply block;
}
.product-catalog__info .price-actual {
  @apply block py-4 text-center;
}
.product-catalog__info .price-actual .rebatepercent {
  @apply hidden;
}
.product-catalog__info .catalog-detail-basic__header {
  @apply flex pb-8 items-center justify-between;
}
.product-catalog__info .catalog-detail-basket-attribute .selection,
.product-catalog__info .catalog-detail-basket-selection .selection {
  @apply flex flex-wrap justify-center;
}
.product-catalog__info .catalog-detail-basket-attribute .selection .select-item,
.product-catalog__info .catalog-detail-basket-selection .selection .select-item {
  @apply pr-4 pt-4 last:pr-0 last:pb-1;
}
.product-catalog__info .catalog-detail-basket-attribute .selection .select-item .select-name,
.product-catalog__info .catalog-detail-basket-selection .selection .select-item .select-name {
  @apply block text-center font-medium pb-2;
}
.product-catalog__info .catalog-detail-basket-attribute .selection .select-item select, .product-catalog__info .catalog-detail-basket-attribute .selection .select-item input,
.product-catalog__info .catalog-detail-basket-selection .selection .select-item select,
.product-catalog__info .catalog-detail-basket-selection .selection .select-item input {
  @apply rounded-full border border-white-rock px-6 pr-8;
}
.product-catalog__info .catalog-detail-basket-attribute .selection:empty,
.product-catalog__info .catalog-detail-basket-selection .selection:empty {
  @apply hidden;
}
.product-catalog__info .catalog-detail-basket-selection .selection {
  @apply flex;
}
.product-catalog__info .catalog-detail-basket-selection .select-item {
  @apply max-w-full;
}
.product-catalog__info .catalog-detail-basket-selection .select-item .select-list {
  @apply flex flex-wrap justify-center;
}
.product-catalog__info .catalog-detail-basket-selection .select-item .select-list .select-entry {
  @apply p-2 px-1 mb-2 relative;
}
.product-catalog__info .catalog-detail-basket-selection .select-item .select-list .select-entry .select-option {
  @apply sr-only;
}
.product-catalog__info .catalog-detail-basket-selection .select-item .select-list .select-entry .select-option:checked + .select-label {
  @apply bg-white-rock;
}
.product-catalog__info .catalog-detail-basket-selection .select-item .select-list .select-entry .select-label {
  @apply font-normal text-base rounded-3xl px-4 py-2 border border-white-rock;
  @apply hover:bg-white-rock;
}
.product-catalog__info .stock-list .articleitem {
  @apply hidden;
}
.product-catalog__info .stock-list .articleitem.stock-actual {
  @apply block;
}
.product-catalog__info .catalog-detail-prebooking__form {
  @apply flex flex-wrap justify-center;
}
.product-catalog__info .catalog-detail-prebooking__form .form-item {
  @apply pb-2 max-w-md px-3;
}
.product-catalog__info .catalog-detail-prebooking__form .form-item label {
  @apply block text-center font-medium pb-2;
}
.product-catalog__info .catalog-detail-prebooking__form .form-item input, .product-catalog__info .catalog-detail-prebooking__form .form-item textarea {
  @apply rounded-full border border-white-rock px-6 pr-8 w-full h-11;
}
.product-catalog__info .catalog-detail-prebooking__form .form-item textarea {
  @apply rounded-3xl;
}
.product-catalog__info .catalog-detail-prebooking__form .form-item--button {
  @apply max-w-full w-full flex justify-center;
}
.product-catalog__info .catalog-detail-prebooking__success {
  @apply hidden text-center;
}

.catalog-stage-breadcrumb .breadcrumb {
  @apply py-5 md:pt-7;
}

.products-summary-table {
  @apply w-full;
}
.products-summary-table thead {
  @apply border-t border-white-rock;
}
.products-summary-table th {
  @apply font-light py-2 px-4 text-right first:text-left first:pl-0;
}
.products-summary-table th.quantity {
  @apply hidden md:table-cell;
}
.products-summary-table tr {
  @apply relative w-full flex flex-wrap md:table-row border-b border-white-rock;
}
.products-summary-table tr .details,
.products-summary-table tr .products-summary-table__receipt {
  @apply flex-1;
}
.products-summary-table tr .quantity {
  @apply text-left pl-110px ml-3.5 min-w-full;
  @apply md:pl-0 md:ml-0 md:min-w-[auto] md:w-12 md:text-right;
}
.products-summary-table tr .quantity .td-label {
  @apply font-medium mr-3 md:mr-0;
}
.products-summary-table tr .unitprice,
.products-summary-table tr .price {
  @apply hidden text-right font-bold;
  @apply md:table-cell;
}
.products-summary-table tr th.action {
  @apply absolute;
}
.products-summary-table td {
  @apply px-2 py-4 text-right;
  @apply first:text-left first:pl-0 last:pr-0;
}
.products-summary-table td .td-label {
  @apply md:hidden;
}
.products-summary-table td .supplier-name {
  @apply font-sans font-medium;
}
.products-summary-table td.details {
  @apply font-medium text-left pr-9 md:px-2;
}
.products-summary-table td.details .product-info {
  @apply pt-3;
  @apply md:hidden;
}
.products-summary-table td.details .product-info__label {
  @apply font-light;
}
.products-summary-table td.image {
  @apply text-left pr-0 w-110px;
}
.products-summary-table td.image .detail-img {
  @apply block w-full h-auto;
}
.products-summary-table td.image + td {
  @apply pl-3.5;
}
.products-summary-table td.action {
  @apply absolute top-[calc(50%-3.5rem)] z-20 right-0;
  @apply md:top-1/2 md:-translate-y-1/2 md:left-full;
}
.products-summary-table td:empty {
  @apply p-0 text-none;
}
.products-summary-table .quantity__field {
  @apply inline-flex items-center max-w-full relative;
}
.products-summary-table .quantity__field input {
  @apply font-bold underline text-right h-auto pl-2 py-0 border-none max-w-full w-16 pr-6 z-10 bg-transparent;
  @apply md:w-20 md:bg-white;
}
.products-summary-table .quantity__field .minibutton {
  @apply absolute right-0 z-20 select-none;
}
.products-summary-table .quantity__field .minibutton-change--plus {
  @apply top-0;
}
.products-summary-table .quantity__field .minibutton-change--minus {
  @apply bottom-0;
}
.products-summary-table .quantity__field__receipt {
  @apply text-base leading-6 lg:leading-8;
}

.product-item .badges,
.product__page .badges,
.watch-items .watch-item .badges {
  @apply absolute right-0 top-1 z-20 m-2 flex flex-col;
}
.product-item .badges .badge-item,
.product__page .badges .badge-item,
.watch-items .watch-item .badges .badge-item {
  @apply inline-flex ml-auto rounded-full px-3 py-1 text-sm shadow-md font-medium bg-black text-white leading-none mb-1 last:mb-0;
}
.product-item .rebatepercent,
.product__page .rebatepercent,
.watch-items .watch-item .rebatepercent {
  @apply absolute top-2 -left-12 md:top-4 md:-left-10 -rotate-45 py-3 z-10 w-40 text-center font-bold bg-primary text-white;
}

.intro-block {
  @apply py-20 md:pt-32 text-center;
}
.intro-block__inner {
  @apply max-w-xl mx-auto;
}
.intro-block__icon {
  @apply max-w-66px mx-auto mb-7;
}
.intro-block__title {
  @apply text-2xl mb-2 md:text-46px md:mb-4;
}
.intro-block__content {
  @apply md:leading-7;
}
.intro-block__btn {
  @apply pt-6 md:pt-9;
}

.shop-list {
  @apply py-24 lg:py-28;
}
.shop-list__heading {
  @apply block text-center max-w-432px mx-auto pb-12;
}
.shop-list__heading-title {
  @apply text-2xl md:text-46px mb-2 md:mb-4;
}
.shop-list .product-list__items .list-container {
  @apply px-0 grid gap-4 grid-cols-2;
  @apply md:grid-cols-3;
  @apply lg:grid-cols-5;
}
.shop-list .product-list__items .list-container .top-item {
  @apply bg-white;
}
.shop-list .product-list__items .top-item.has-submenu .item-links {
  @apply block;
}
.shop-list .product-list__items .top-item.has-submenu .submenu {
  @apply hidden;
}
.shop-list__item {
  @apply bg-spring-wood shadow-card-item;
}
.shop-list__image {
  @apply relative;
}
.shop-list__image-thumbnail {
  @apply w-full align-top;
}
.shop-list__supplier-flag {
  @apply absolute z-20 left-4 -translate-y-2/4;
  @apply inline-flex justify-center items-center text-roman border border-white-rock transition-all rounded-full w-action-btn-size h-action-btn-size overflow-hidden bg-white;
}
.shop-list__content {
  @apply py-8 px-6;
}
.shop-list__title {
  @apply text-2xl mb-2;
}
.shop-list__description {
  @apply line-clamp-2;
}
.shop-list__link {
  @apply inline-flex items-center font-medium mt-1 text-primary no-underline;
  @apply hover:text-roman;
}
.shop-list__btn {
  @apply flex justify-center pt-6 md:pt-12;
}
.shop-list.bg-primary .shop-list__btn .btn {
  @apply bg-white text-primary;
  @apply hover:bg-spring-wood;
}

.bg-mine-shaft + .newsletter-block {
  @apply md:bg-mine-shaft;
}

.newsletter-block {
  @apply pt-11 md:pt-0;
  @apply md:mb-7;
}
.newsletter-block__container {
  @apply relative z-20 flex h-full;
}
.newsletter-block__inner {
  @apply ml-auto sm:max-w-8/10;
  @apply md:max-w-500px md:-mb-7;
}
.newsletter-block__bg-image.banner-block__bg-image {
  @apply absolute object-cover top-0 max-h-none h-4/5;
  @apply md:top-4 md:h-full;
}
.newsletter-block__newsletter-form {
  @apply pt-5 md:pt-10;
}

.checkout-standard {
  @apply relative pb-10;
}
.checkout-standard__title {
  @apply text-center mb-3;
}
.checkout-standard .steps {
  @apply flex flex-wrap items-center justify-center text-center pb-6;
}
.checkout-standard .steps .step {
  @apply px-2.5 py-1.5 font-medium relative;
}
.checkout-standard .steps .step__label {
  @apply block rounded-full text-base border border-white-rock text-black py-2 px-7 transition;
}
.checkout-standard .steps .step a {
  @apply block;
}
.checkout-standard .steps .step.current a, .checkout-standard .steps .step.current span {
  @apply bg-white-rock;
}
.checkout-standard .error-list {
  @apply text-center;
}

.checkout-standard-address {
  @apply block;
}
.checkout-standard-address .form-horizontal {
  @apply border-t border-white-rock py-3.5 md:pt-8;
}
.checkout-standard-address .form-horizontal__item {
  @apply py-3.5 max-w-800px mx-auto border-b border-white-rock last:border-none;
  @apply md:px-0 md:py-8;
}
.checkout-standard-address .checkout-standard-address {
  @apply py-4 flex justify-center;
}
.checkout-standard-address .block-heading__title {
  @apply text-center mb-2;
}
.checkout-standard-address .item-address {
  @apply flex flex-col;
}
.checkout-standard-address .item-address .header {
  @apply flex w-full md:text-center md:justify-center pb-2 md:items-center last:pb-3;
}
.checkout-standard-address .item-address .header input {
  @apply inline-flex w-18px h-18px border border-primary mr-3 rounded-none text-primary relative;
}
.checkout-standard-address .item-address .header input.checkbox--new-option {
  @apply sr-only;
}
.checkout-standard-address .item-address .header input.checkbox--new-option + label {
  @apply font-medium text-primary underline;
  @apply hover:no-underline;
}
.checkout-standard-address .item-address .header label {
  @apply inline-flex;
}
.checkout-standard-address .item-address .form-item.error input,
.checkout-standard-address .item-address .form-item.error input:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.checkout-standard-delivery,
.checkout-standard-payment {
  @apply py-6 border-t border-b border-white-rock md:pt-12;
}
.checkout-standard-delivery .block-heading__title,
.checkout-standard-payment .block-heading__title {
  @apply text-center;
}
.checkout-standard-delivery .item-service,
.checkout-standard-payment .item-service {
  @apply max-w-800px mx-auto pb-4 last:pb-6;
}
.checkout-standard-delivery .item-service__label,
.checkout-standard-payment .item-service__label {
  @apply flex items-center rounded-3xl px-4 py-2 border border-white-rock overflow-hidden justify-between bg-white;
  @apply md:rounded-full md:py-1.5 md:px-6;
}
.checkout-standard-delivery .item-service__label-heading,
.checkout-standard-payment .item-service__label-heading {
  @apply flex items-center pr-4 max-w-[70%];
}
.checkout-standard-delivery .item-service__label-info,
.checkout-standard-payment .item-service__label-info {
  @apply flex flex-wrap md:flex-nowrap items-center justify-end;
}
.checkout-standard-delivery .item-service__radio,
.checkout-standard-payment .item-service__radio {
  @apply text-primary mr-3;
}
.checkout-standard-delivery .item-service_information,
.checkout-standard-payment .item-service_information {
  @apply flex;
}
.checkout-standard-delivery .item-service__title,
.checkout-standard-payment .item-service__title {
  @apply font-sans font-medium text-base md:text-lg mr-2;
}
.checkout-standard-delivery .item-service__info,
.checkout-standard-payment .item-service__info {
  @apply text-xs lg:text-base;
}
.checkout-standard-delivery .item-service__price,
.checkout-standard-payment .item-service__price {
  @apply font-bold md:pr-3;
}
.checkout-standard-delivery .item-service__icon img,
.checkout-standard-payment .item-service__icon img {
  @apply max-w-66px h-auto;
  @apply md:max-w-82px;
}
.checkout-standard-delivery .form-list .col-md-5 label.form-item-label,
.checkout-standard-payment .form-list .col-md-5 label.form-item-label {
  @apply static block font-medium w-auto h-auto capitalize pl-5 pb-2;
}
.checkout-standard-delivery__button-group,
.checkout-standard-payment__button-group {
  @apply md:py-8 mt-6;
}

.checkout-standard-payment {
  @apply border-none;
}
.checkout-standard-payment__button-group {
  @apply md:py-8 mt-6 border-none pt-0;
}

.checkout-standard-summary {
  @apply py-6 border-t border-white-rock md:pt-12;
}
.checkout-standard-summary .common-summary-address,
.checkout-standard-summary .common-summary-service,
.checkout-standard-summary .common-summary-coupon,
.checkout-standard-summary .common-summary-custom-message,
.checkout-standard-summary .common-summary-detail,
.checkout-standard-summary .checkout-standard-summary-option-terms {
  @apply max-w-784px mx-auto;
}
.checkout-standard-summary .common-summary-address .item,
.checkout-standard-summary .common-summary-service .item,
.checkout-standard-summary .common-summary-coupon .item {
  @apply flex flex-col leading-snug pb-3;
}
.checkout-standard-summary .common-summary-address .item__title,
.checkout-standard-summary .common-summary-service .item__title,
.checkout-standard-summary .common-summary-coupon .item__title {
  @apply mb-1 mr-2 leading-snug font-light font-sans;
}
.checkout-standard-summary .common-summary-address .item .content,
.checkout-standard-summary .common-summary-service .item .content,
.checkout-standard-summary .common-summary-coupon .item .content {
  @apply font-medium inline-flex leading-snug;
}
.checkout-standard-summary .common-summary-address .item .content img,
.checkout-standard-summary .common-summary-address .item .content .item-icons,
.checkout-standard-summary .common-summary-service .item .content img,
.checkout-standard-summary .common-summary-service .item .content .item-icons,
.checkout-standard-summary .common-summary-coupon .item .content img,
.checkout-standard-summary .common-summary-coupon .item .content .item-icons {
  @apply hidden;
}
.checkout-standard-summary .common-summary-additional.common-summary-custom-message {
  @apply mt-2;
}
.checkout-standard-summary .common-summary-custom-message,
.checkout-standard-summary .checkout-standard-summary-option-terms {
  @apply mt-6 py-6 border-t border-b border-white-rock;
}
.checkout-standard-summary .common-summary-custom-message .block-heading__title,
.checkout-standard-summary .checkout-standard-summary-option-terms .block-heading__title {
  @apply mb-2;
}
.checkout-standard-summary .common-summary-custom-message .comment-value,
.checkout-standard-summary .checkout-standard-summary-option-terms .comment-value {
  @apply w-full rounded-full border-white-rock py-3 px-6 font-light text-base resize-none;
}
.checkout-standard-summary .checkout-standard-summary-option-terms {
  @apply border-none mt-0;
}
.checkout-standard-summary .checkout-standard-summary-option-terms__field {
  @apply flex items-start justify-start;
}
.checkout-standard-summary .checkout-standard-summary-option-terms__field input {
  @apply mr-2 leading-none relative top-0.5 text-primary;
}
.checkout-standard-summary .checkout-standard-summary-option-terms__field a {
  @apply text-primary underline;
  @apply hover:no-underline;
  @apply focus:no-underline;
}
.checkout-standard-summary .common-summary-detail {
  @apply block mt-3;
}
.checkout-standard-summary__button-group {
  @apply border-t-0;
}

.checkout-standard-process {
  @apply py-6 border-t border-white-rock md:pt-12;
}
.checkout-standard-process__heading {
  @apply pb-6;
}
.checkout-standard-process__heading .block-heading__title {
  @apply mb-1;
}

.checkout-confirm .checkout-confirm-intro {
  @apply max-w-800px mx-auto text-center pb-6 border-b border-white-rock;
}
.checkout-confirm .checkout-confirm-basic,
.checkout-confirm .checkout-confirm-detail {
  @apply max-w-800px mx-auto py-6 border-b border-white-rock text-center;
}
.checkout-confirm .checkout-confirm-basic .block-heading__title,
.checkout-confirm .checkout-confirm-detail .block-heading__title {
  @apply mb-0.5;
}
.checkout-confirm .checkout-confirm-detail {
  @apply border-b-0;
}
.checkout-confirm .checkout-confirm-detail .item {
  @apply block pb-4;
}
.checkout-confirm .checkout-confirm-detail .item .item {
  @apply pb-0;
}
.checkout-confirm .checkout-confirm-detail .common-summary-detail {
  @apply py-4;
}

.cms-page h1, .cms-page h2, .cms-page h3, .cms-page h4, .cms-page h5, .cms-page h6 {
  @apply font-bold font-serif;
}
.cms-page h1 {
  @apply text-3xl md:text-56px mb-4;
}
.cms-page h2 {
  @apply text-34px mb-2 md:text-46px md:mb-4;
}
.cms-page h3 {
  @apply text-xl mb-2 md:text-2xl md:mb-2.5;
}
.cms-page h4 {
  @apply text-xl mb-2;
}
.cms-page h5 {
  @apply text-lg mb-1;
}
.cms-page h6 {
  @apply text-base mb-1;
}
.cms-page h1 + p, .cms-page h2 + p, .cms-page h3 + p, .cms-page h4 + p {
  @apply -mt-1;
}
.cms-page p, .cms-page .btn, .cms-page .row {
  @apply my-3;
}
.cms-page div.row {
  @apply flex flex-wrap;
}
.cms-page div.row .col {
  @apply flex-1 px-4 py-3 bg-white m-3 my-1.5;
}
.cms-page div.row .col img + div, .cms-page div.row .col img + p {
  @apply px-6;
}
.cms-page div.row .col:empty {
  @apply flex-grow-0 bg-transparent;
}
.cms-page div.row .col:empty + .col:not(:empty) {
  @apply max-w-800px mx-auto;
}
.cms-page .container-xl {
  @apply mx-auto w-full px-4 max-w-container;
}
.cms-page img,
.cms-page video,
.cms-page iframe {
  @apply max-w-full w-auto h-auto;
}
.cms-page iframe:not(iframe[src]) {
  @apply hidden;
}
.cms-page table {
  @apply w-full max-w-full border-t border-white-rock;
}
.cms-page table tr {
  @apply border-b border-white-rock;
}
.cms-page table tr td, .cms-page table tr th {
  @apply p-2;
}
.cms-page .contact-form {
  @apply flex flex-wrap py-4 max-w-800px mx-auto;
}
.cms-page .contact-form .form-group {
  @apply w-full flex flex-col relative;
}
.cms-page .contact-form .form-group label {
  @apply block font-medium absolute top-7 -translate-y-1/2 pl-6 md:pl-8;
}
.cms-page .contact-form .form-group input,
.cms-page .contact-form .form-group textarea {
  @apply w-full rounded-full border-white-rock py-3 px-6 font-light text-base;
  @apply md:py-4 md:px-8;
}
.cms-page .contact-form .form-group textarea {
  @apply h-14 resize-none;
}
.cms-page .contact-form .form-group.contact-name, .cms-page .contact-form .form-group.contact-email {
  @apply max-w-1/2;
}
.cms-page .contact-form .form-group.contact-name {
  @apply pr-2;
}
.cms-page .contact-form .form-group.contact-email {
  @apply pl-2;
}
.cms-page .contact-form .contact-pot {
  @apply sr-only;
}
.cms-page img + h1, .cms-page img + h2, .cms-page img + h3, .cms-page img + h4, .cms-page img + p, .cms-page img + div {
  @apply mt-4;
}
.cms-page a:not(.btn) {
  @apply text-primary underline;
  @apply hover:text-opacity-75 hover:no-underline;
  @apply focus:text-opacity-75 focus:no-underline;
}
.cms-page .product-banner,
.cms-page .catalog-stage,
.cms-page aside.col-lg-3,
.cms-page .pagination,
.cms-page .catalog-filter-group,
.cms-page .catalog-filter-group {
  @apply hidden;
}
.cms-page .list-items.sliick-slider {
  @apply block;
}
.cms-page .background {
  @apply block bg-black bg-opacity-60 bg-cover bg-no-repeat bg-center mb-10;
}
.cms-page .background .row {
  @apply bg-white bg-opacity-80 md:bg-opacity-100 md:max-w-xl pb-10 py-7 px-6 md:p-16 shadow-md;
}
.cms-page .background .row .col {
  @apply bg-transparent;
}
.cms-page.container-fluid {
  @apply px-0;
}

.account-template {
  @apply py-8;
}
.account-template__title {
  @apply mb-1 pb-4;
}
.account-template__nav {
  @apply text-center;
}
.account-template__nav-list {
  @apply flex flex-wrap justify-center -mx-2;
}
.account-template__nav-item {
  @apply py-1 px-2;
}
.account-template__nav-item .btn {
  @apply bg-white border border-white-rock text-black;
  @apply hover:bg-white-rock hover:text-black;
  @apply focus:bg-white-rock focus:text-black;
}
.account-template__nav-item .btn.active {
  @apply bg-white-rock text-black;
}
.account-template__wrapper {
  @apply text-center py-6;
}
.account-template__wrapper .account-profile,
.account-template__wrapper .account-history,
.account-template__wrapper .account-watch,
.account-template__wrapper .change-password {
  @apply max-w-800px mx-auto;
}
.account-template__wrapper .account-profile .billing,
.account-template__wrapper .account-history .billing,
.account-template__wrapper .account-watch .billing,
.account-template__wrapper .change-password .billing {
  @apply pb-4;
}
.account-template__wrapper .account-review,
.account-template__wrapper .account-subscription,
.account-template__wrapper .account-history,
.account-template__wrapper .account-profile,
.account-template__wrapper .account-favorite,
.account-template__wrapper .account-watch {
  @apply hidden;
}
.account-template__wrapper .block-heading__title {
  @apply mb-1;
}
.account-template__wrapper .account-history {
  @apply text-center;
}
.account-template__wrapper .account-history .account-history-order {
  @apply text-base px-6 py-5 md:px-12 md:py-10;
}
.account-template__wrapper .account-history .button-group {
  @apply pt-4;
}
.account-template__wrapper .account-history .history-item {
  @apply mb-5 last:mb-0 text-left;
}
.account-template__wrapper .account-history .history-item .accordian-item__opener-icon {
  @apply inset-0 flex justify-end items-center transform-none;
}
.account-template__wrapper .account-history .history-item__header .btn {
  @apply bg-transparent text-black;
}
.account-template .account-profile .panel-heading {
  @apply inline-flex text-primary underline font-medium cursor-pointer;
  @apply focus:no-underline;
  @apply hover:no-underline;
}
.account-template .account-profile .panel-body {
  @apply pb-3;
}
.account-template .account-profile .panel-body.collapse {
  @apply hidden;
}
.account-template .account-profile .panel-body.collapse.show {
  @apply block;
}

.account-watch__title {
  @apply block text-center text-lg text-opacity-50 text-black mb-6;
}

.watch-items {
  @apply md:flex flex-wrap gap-4 justify-center pt-1 px-3.5 md:px-0;
  @apply md:grid-cols-2;
}
.watch-items .watch-item {
  @apply flex flex-col justify-between relative shadow-md bg-white text-left mb-3.5 md:mb-0 overflow-hidden;
  @apply md:w-[280px];
}
.watch-items .watch-item .media-item {
  @apply bg-gray-500 w-full pb-image-height mb-2 bg-cover bg-center;
}
.watch-items .watch-item .watch-details {
  @apply mb-4;
}
.watch-items .watch-item .modify {
  @apply absolute top-0 right-0 p-2 m-3 text-white text-center w-action-btn-size h-action-btn-size inline-flex justify-center;
}
.watch-items .watch-item .form-list {
  @apply flex flex-col;
}
.watch-items .watch-item .button-group {
  @apply pt-4;
}
.watch-items .watch-item__description {
  @apply px-6 py-2;
}
.watch-items input[type=checkbox],
.watch-items input[type=radio] {
  @apply ml-2 text-primary;
}
.watch-items select {
  @apply border-none p-0 m-0 ml-2 w-auto pr-3;
}

.slick-list {
  @apply relative;
  @apply w-full;
  @apply overflow-hidden;
}
.js-blog-images .slick-list {
  @apply h-full;
}

.catalog-detail-image .image-single,
.catalog-detail-image .thumbs {
  @apply opacity-0;
  @apply transition-opacity;
}

.catalog-detail-image .image-single.slick-initialized,
.catalog-detail-image .thumbs {
  @apply opacity-100;
}

.slick-track {
  @apply flex;
  @apply w-full;
  @apply max-w-full;
  @apply whitespace-nowrap;
}
.js-blog-images .slick-track {
  @apply h-full;
}

.catalog-detail-image .slick-track .media-item {
  @apply min-w-full;
  @apply whitespace-normal;
}

.catalog-detail-image .thumbs,
.js-blog-thumbs {
  @apply flex;
  @apply relative;
  @apply items-center;
  @apply pb-1;
}

.catalog-detail-image .thumbs .slick-track {
  @apply flex-1;
}

.catalog-detail-image .thumbs .item-thumb {
  @apply block;
  @apply max-w-1/4;
  @apply mx-2;
  @apply shadow-md;
  @apply object-cover;
}

.catalog-detail-image .thumbs .slick-arrow,
.js-blog-thumbs .slick-arrow,
.js-news-slider .slick-arrow {
  @apply text-transparent;
  @apply transition-all;
  @apply bg-center;
  font-size: 0;
  line-height: 0;
  width: 57px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 17'%3E%3Cpath fill='%2339605E' fill-rule='nonzero' d='m15.671.963.104.08 8.925 7.58-8.944 7.27a1.2 1.2 0 0 1-1.611-1.774l.097-.087 4.985-4.055H1.786a1.2 1.2 0 0 1-.123-2.393l.123-.006h17.976l-5.54-4.706a1.2 1.2 0 0 1-.217-1.587l.079-.105a1.2 1.2 0 0 1 1.587-.217Z'/%3E%3C/svg%3E");
  background-size: 25px 16px;
  background-repeat: no-repeat;
  /* Hover */
  @apply hover:opacity-60;
  @apply focus:opacity-60;
}
.catalog-detail-image .thumbs .slick-arrow.slick-disabled,
.js-blog-thumbs .slick-arrow.slick-disabled,
.js-news-slider .slick-arrow.slick-disabled {
  @apply opacity-60 pointer-events-none;
}

.catalog-detail-image .thumbs .slick-arrow.slick-prev,
.js-blog-thumbs .slick-arrow.slick-prev,
.js-news-slider .slick-arrow.slick-prev {
  transform: scaleX(-1);
}

.js-blog-thumbs {
  @apply flex justify-center pt-7 px-5;
}
.js-blog-thumbs li {
  @apply relative;
}
.js-blog-thumbs li img {
  @apply w-full h-full object-cover;
}
.js-blog-thumbs.slick-initialized {
  @apply mb-4;
}

.js-blog-thumbs .slick-track {
  padding: 0 !important;
  @apply flex justify-center;
}

.js-news-slider.slick-initialized {
  @apply flex-wrap overflow-visible justify-center;
}
.js-news-slider.slick-initialized .slick-list {
  @apply order-1 pb-4;
}
.js-news-slider.slick-initialized .slick-track {
  @apply -mx-4;
}
.js-news-slider.slick-initialized .slick-prev {
  @apply order-2;
}
.js-news-slider.slick-initialized .slick-dots {
  @apply flex order-3 items-start h-4 text-none;
}
.js-news-slider.slick-initialized .slick-dots li {
  @apply px-0.5;
  @apply only:hidden;
}
.js-news-slider.slick-initialized .slick-dots li button {
  @apply w-4 h-4 rounded-full bg-akaroa text-none transition-all duration-200 ease-in-out;
  @apply hover:bg-primary;
}
.js-news-slider.slick-initialized .slick-dots li.slick-active button {
  @apply bg-primary;
}
.js-news-slider.slick-initialized .slick-next {
  @apply order-4;
}

.js-news-slider .slick-list,
.js-news-slider .slick-track {
  @apply flex overflow-visible;
}
.js-news-slider .slick-list .news-block__item,
.js-news-slider .slick-track .news-block__item {
  @apply opacity-50 transition-opacity;
}
.js-news-slider .slick-list .news-block__item .news-block__article,
.js-news-slider .slick-track .news-block__item .news-block__article {
  @apply pointer-events-none;
}
.js-news-slider .slick-list .news-block__item.slick-active,
.js-news-slider .slick-track .news-block__item.slick-active {
  @apply opacity-100;
}
.js-news-slider .slick-list .news-block__item.slick-active .news-block__article,
.js-news-slider .slick-track .news-block__item.slick-active .news-block__article {
  @apply pointer-events-auto;
}